<template>
  <div class="contain">
    <div class="contain-left">
      <div class="contain-header">
        <img
            :src="currentInfo.avatarUrl"
            alt="">
      </div>
      <div class="contain-title">参赛者: {{currentInfo.name}}</div>
      <div class="contain-about">
        参赛者简介: {{currentInfo.descContent}}
      </div>
    </div>
    <div class="contain-right">
      <el-table
          :data="playerData"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          :summary-method="getSummaries"
          show-summary
          style="width: 100%">
        <el-table-column label="评委编号" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.judgeCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="基础" align="center" v-if="eventInfo.scoreStandard===1">
          <template slot-scope="scope">
            <span style="color: #b14040">
              {{ scope.row.eventJudgeScore ? scope.row.eventJudgeScore.scoreA : '未评分' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="基础" align="center" v-if="eventInfo.scoreStandard===2">
          <template slot-scope="scope">
            <span style="color: #b14040">
              {{ scope.row.eventJudgeScore ? scope.row.eventJudgeScore.scoreB : '未评分' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="基础" align="center" v-if="eventInfo.scoreStandard===3">
          <template slot-scope="scope">
            <span style="color: #b14040">
              {{ scope.row.eventJudgeScore ? scope.row.eventJudgeScore.scoreC : '未评分' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创新" align="center" v-if="eventInfo.scoreStandard===2">
          <template slot-scope="scope">
            <span style="color: #b14040">{{
                scope.row.eventJudgeScore ? scope.row.eventJudgeScore.innovationScoreB : '未评分'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="破纪录" align="center" v-if="eventInfo.scoreStandard===2" prop="score">
          <template slot-scope="scope">
            <span style="color: #b14040">{{
                scope.row.eventJudgeScore ? scope.row.eventJudgeScore.recordScoreB : '未评分'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="难度" align="center" v-if="eventInfo.scoreStandard===2">
          <template slot-scope="scope">
            <span style="color: #b14040">{{
                scope.row.eventJudgeScore ? scope.row.eventJudgeScore.difficultyScoreB : '未评分'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="resetScore(scope.row.eventJudgeScore.id)" v-if="scope.row.eventJudgeScore" type="text">重新打分</el-button>
            <el-button disabled v-if="!scope.row.eventJudgeScore" type="text">重新打分</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getCurrentPlayerScoreInfo,resetScore} from "@/api/event";

export default {
  name: "chiefJudge",
  data() {
    return {
      playerData: [],
      eventInfo: {},
      //当前选手信息
      currentInfo: {}
    }
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.getCurrentPlayerScoreInfo(this.eventInfo.id)
  },
  methods: {
    resetScore(id){
      this.$confirm('重新打分, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const {data}=await resetScore(id)
        if(data.code===200){
         await this.getCurrentPlayerScoreInfo(this.eventInfo.id)
          this.$message.success('操作成功')
        }
      })
    },
    async getCurrentPlayerScoreInfo(id) {
      const {data} = await getCurrentPlayerScoreInfo(id)
      if (data.code === 200) {
        this.currentInfo = data.data.currentInfo
        this.playerData = data.data.playerData
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        // const values = data.map(item => Number(item[column.property]));
        sums[2] = this.currentInfo.finalScore?this.currentInfo.finalScore:'评分未结束';
      });

      return sums;
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/scoreBanner.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .contain-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contain-header {
      width: 100px;
      height: 100px;
      margin: 10px 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contain-title {
      margin: 10px 0;
      font-weight: bold;
      font-size: 18px;
    }

    .contain-about {
      margin: 10px 0;
      width: 80%;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
    }
  }

  .contain-right {
    width: 60%;
    height: 300px;
  }
}
</style>
